// const SITE_BASE_URL = 'http://127.0.0.1:8000/api/';
const SITE_BASE_URL = 'https://api.hitnish.com/api/';

export const API_URL = {
    SITE_CONTACT_ADD: `${SITE_BASE_URL}save-inquiry`,
    SITE_QUOATATION_ADD: `${SITE_BASE_URL}save-quotation`,
    SITE_CONTACT_INFO: `${SITE_BASE_URL}contact-info`,
    SITE_PRODUCT: `${SITE_BASE_URL}products`,
    SITE_PRODUCT_BY_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-category-slug`,
    SITE_SUBCATEGORY: `${SITE_BASE_URL}sub-category-by-category`,
    SITE_PRODUCT_BY_SUB_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-sub-category-slug`,
    SITE_PRODUCT_BY_SIZE_SLUG: `${SITE_BASE_URL}products-by-size-slug`,
    SITE_PRODUCT_BY_SERIES_SLUG: `${SITE_BASE_URL}products-by-series-slug`,
    SITE_SERIES_BY_CATEGORY: `${SITE_BASE_URL}series-by-category`,
    SITE_PRODUCT_BY_ID: `${SITE_BASE_URL}product-by-id`,
    SITE_PRODUCT_BY_SLUG: `${SITE_BASE_URL}product-by-slug`,
    SITE_CATEGORY: `${SITE_BASE_URL}all-category`,
    SITE_COLOR: `${SITE_BASE_URL}colors`,
    SITE_TILES_CATEGORY: `${SITE_BASE_URL}tiles-category`,
    SITE_SIZES_BY_CATEGORY: `${SITE_BASE_URL}size-by-category`,
    SITE_COUNTRIES: `${SITE_BASE_URL}countries`,
    SITE_SHOW_CATEGORY: `${SITE_BASE_URL}show-category`,
    SITE_SHOW_SUB_CATEGORY: `${SITE_BASE_URL}show-sub-category`,
    SITE_PACKINGS: `${SITE_BASE_URL}packing-details`,
    SITE_TECHNICAL: `${SITE_BASE_URL}all-technical-specification`,
    SITE_NEWS: `${SITE_BASE_URL}news`,
    SITE_NEWS_BY_SLUG: `${SITE_BASE_URL}news-by-slug`,
    SITE_PDFS: `${SITE_BASE_URL}pdfs-list`,
    SITE_INFRASTRUCTURE_CATEGORY: `${SITE_BASE_URL}infrastructure-category`,
    SITE_SLIDERS: `${SITE_BASE_URL}sliders`,
    SITE_CARTS: `${SITE_BASE_URL}carts`,
}

