import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { GoPrimitiveDot } from 'react-icons/go'
import { BsCart4 } from 'react-icons/bs'
import {  AiOutlineHeart } from 'react-icons/ai'

const Header = (props, { classNm }) => {

    useEffect(() => {
        const preloader = document.querySelector('#preloader');
        if (preloader) {
            preloader.classList.remove('d-none')
            setTimeout(() => {
                preloader.classList.add('d-none')
            }, 2000)
        }
    }, [])

    return (
        <>  <div id="preloader"><span className="loadertitle"><img className="image" src={require('../img/logo.png')} width={300} alt="Logo" /></span></div>
            <Navbar expand="lg" fixed="top" className={classNm}>
                <Container fluid>
                    <Navbar.Brand >
                        <Link to='/home'>
                            <img src={require('../img/logolight.png')} width={250} alt="Logo" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto"></Nav>
                        <Nav>
                            <Nav.Link as={Link} to="/" className="header-title">Home</Nav.Link>
                            <Nav.Link as={Link} to="/products" className="header-title">Product</Nav.Link>
                            {/* <Nav.Link as={Link} to="/ceramic-tiles-specification" className="header-title">Specification</Nav.Link> */}
                            <Nav.Link as={Link} to="/tiles-calculator" className="header-title">Tiles-Calculator</Nav.Link>
                            <Nav.Link as={Link} to="/about" className="header-title">About</Nav.Link>
                            <Nav.Link as={Link} to="/wishlist" style={{ position: 'relative' }} className="header-title"><AiOutlineHeart size={25} />
                                {props.iswish && <div className="notification"><GoPrimitiveDot className="dot" size={15} /></div>}</Nav.Link>
                            <Nav.Link as={Link} to="/cart" style={{ position: 'relative' }} className="header-title"><BsCart4 size={25} />
                                {props.isempty && <div className="notification"><GoPrimitiveDot className="dot" size={15} /></div>}</Nav.Link>
                            <Nav.Link as={Link} to="/contact" className="header-title">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;