import React, { useEffect, useState } from 'react';
import './App.css';
import CustomRouter from './router/CustomRouter';
import Footer from "./component/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "./component/Header";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./pages/scrollToTop/scrollToTop"

const App = () => {
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [iscartempty, setIsCartEmpty] = useState(false);
  const [iswishempty, setIsWishEmpty] = useState(false);
  useEffect(() => {
    let carts = localStorage.getItem('cartdata');
    let wish = localStorage.getItem('wishlist');
    if (carts && JSON.parse(carts).length > 0) {
      setCount(JSON.parse(carts).length)
      setIsCartEmpty(true)
    } else {
      setIsCartEmpty(false)
    }

    if (wish && JSON.parse(wish).length > 0) {
      setIsWishEmpty(true)
    } else {
      setIsWishEmpty(false)
    }
  }, [location])

  return (
    <>
      <Header classNm={(location.pathname === '/home' || location.pathname === '/') ? 'home-page' : ''} count={count} isempty={iscartempty} iswish={iswishempty} />
      <CustomRouter />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
