import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../component/Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faTwitter, faInstagram, faLinkedin, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { BsWhatsapp } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';

const Footer = () => {

    const [contact, setContact] = useState({
        address_one: '',
        address_title: '',
        address_two: '',
        contact_email: '',
        extra_mobile_number: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        mobile_number: '',
        office_address_one: '',
        office_address_title: '',
        office_address_two: '',
        pinteress: '',
        twitter: '',
        youtube: '',
    });
    const [image, setImage] = useState([]);
    const [category, setCategory] = useState([]);
    const [year, setYear] = useState('');
    const getContactInfo = () => {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.status === 1) {
                setContact(res.data.data);
            }
        }, error => {
        })
    }

    const getSLider = () => {
        axios.post(API_URL.SITE_SLIDERS, {}).then(res => {
            if (res.data.status === 1 && res.data.data.length) {
                setImage(res.data.data[0].image);
            }
        }, error => {
        })
    }

    const getCategoryData = () => {
        axios.post(API_URL.SITE_CATEGORY, {}).then(res => {
            if (res.data.status === 1) {
                setCategory(res.data.data);
            }
        }, error => {
        })
    }

    const paginate = [
        { link: "/home", title: "Home" },
        { link: "/about", title: "About" },
        { link: "/products", title: "Product" },
        { link: "/contact", title: "Contact" }
    ];

    let wpn = contact.extra_mobile_number.replace(/ /g, "");
    let cn = contact.mobile_number.replace(/ /g, "");
    let mailId = 'harsh@indicera.co.in';

    const socialData = [
        { link: contact.facebook, icon: faFacebookF, className: "facebook" },
        { link: contact.twitter, icon: faTwitter, className: "twitter" },
        { link: contact.instagram, icon: faInstagram, className: "instagram" },
        { link: contact.linkedin, icon: faLinkedin, className: "linkedin" },
        { link: contact.pinteress, icon: faPinterest, className: "pinterest" },
        { link: contact.youtube, icon: faYoutube, className: "youtube" },
    ];

    const getyear = () => {
        let date = new Date();
        const year = date.getFullYear()
        setYear(year);
    }

    useEffect(() => {
        getSLider();
        getCategoryData();
        getContactInfo();
        getyear();
    }, []);

    return (
        <>
            <footer className="footer background-section" style={{ backgroundColor: '#e3e3e3', color: 'black' }}>
                <Container>
                    <div className="footer-links">
                        <div className="row">
                            <div className="col-md-3">
                                <Link to='/home'>
                                    <img src={require('../img/logolight.png')} className='ftrlogo' width={250} alt="Logo" />
                                </Link>
                                <ul className='social-icon'>
                                    {socialData.map((item, index) =>
                                        item.link ?
                                            <li key={index} className='mt-3' style={{ marginRight: '10px' }}>
                                                <a style={{ color: 'white' }} href={item.link} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={item.icon} />
                                                </a>
                                            </li> : ''
                                    )}
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h1 className="footer-title">Navigation</h1>
                                <hr className='hr2' />
                                <ul style={{ listStyle: 'none' }}>
                                    {
                                        paginate.map(
                                            (item, i) =>
                                                <li key={i} className='footer-link'><Link to={item.link}>{item.title}</Link></li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h1 className="footer-title">Contact</h1>
                                <hr className='hr2' />
                                <div>
                                    <div className='d-flex'>
                                        <div>
                                            <div className='mb-2'>
                                                Greenora Homes
                                            </div>
                                            <div className='mb-2'>
                                                <span className="me-2"><BsWhatsapp size={20}></BsWhatsapp></span>
                                                <a href={'https://wa.me/' + cn} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: '#000', fontSize: 16 }}>{contact.mobile_number}</a>
                                            </div>
                                            <div className='mb-2'>
                                                <span className="me-2"><HiOutlineMail size={20}></HiOutlineMail></span>
                                                <a href={'mailto:' + contact.contact_email} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}> {contact.contact_email}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <h1 className="footer-title">Address</h1>
                                <hr className='hr2' />
                                <p className='mb-2'>{contact.address_one}</p>
                                <p>{contact.address_two}</p> <br />
                            </div>
                        </div>
                    </div>
                    <div className="text-center p-4">
                        <span className="footer-title-text ">Copyright © {year} <strong>Greenora Homes.</strong> Developed by <Link target='_blank' to='https://hitnish.com/' ><span style={{ color: 'black' }}><strong>Hitnish</strong></span></Link></span>
                    </div>
                </Container>
            </footer>
            <div className="icon-bar">
                {socialData.map((item, key) =>
                    item.link ? <a href={item.link} key={key} target="_blank" className={item.className}>  <FontAwesomeIcon icon={item.icon} /></a> : ''
                )}
            </div>
        </>
    )
}

export default Footer;