import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
const Home = React.lazy(() => import("../pages/home/home"));
const About = React.lazy(() => import("../pages/about/about"));
const Contact = React.lazy(() => import("../pages/contact/contact"));
const Product = React.lazy(() => import("../pages/product/product"));
const Specification = React.lazy(() => import("../pages/technical-specs/specification"));
const Calculator = React.lazy(() => import("../pages/calculator/calculator"));
const ProductDetails = React.lazy(() => import("../pages/product/product-detail"));
const Cart = React.lazy(() => import("../pages/cart/cart-list"));
const Wishlist=React.lazy(() => import("../pages/wishlist/wishlist"));

const CustomRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Suspense fallback=''><Home /></Suspense>} />
            <Route path='/home' element={<Suspense fallback=''><Home /></Suspense>} />
            <Route path='/cart' element={<Suspense fallback=''><Cart /></Suspense>} />
            <Route path='/wishlist' element={<Suspense fallback=''><Wishlist /></Suspense>} />
            <Route path='/about' element={<Suspense fallback=''><About /></Suspense>} />
            <Route path='/contact' element={<Suspense fallback=''><Contact /></Suspense>} />
            <Route path='/products' element={<Suspense fallback=''><Product /></Suspense>} />
            <Route path='/products/:slug' element={<Suspense fallback=''><Product /></Suspense>} />
            <Route path='/products/:slug/:pslug' element={<Suspense fallback=''><ProductDetails /></Suspense>} />
            <Route path='/ceramic-tiles-specification' element={<Suspense fallback=''><Specification /></Suspense>} />
            <Route path='/tiles-calculator' element={<Suspense fallback=''><Calculator /></Suspense>} />
        </Routes>
    )
}

export default React.memo(CustomRouter);